import React, { useState } from "react";
import styles from "../styles/components/HamburgerButton.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

interface HamburgerButtonProps {
  setIsLeftSectionVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const HamburgerButton: React.FC<HamburgerButtonProps> = ({
  setIsLeftSectionVisible,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    setIsLeftSectionVisible((prev) => !prev); // Atualiza a visibilidade da seção esquerda
  };

  return (
    <button
      className={styles.hamburgerButton}
      onClick={toggleMenu}
      aria-label="Menu"
    >
      <FontAwesomeIcon
        icon={faBars}
        className={`${styles.icon} ${!isOpen ? styles.active : styles.hidden}`}
      />
      <FontAwesomeIcon
        icon={faX}
        className={`${styles.icon} ${styles.x} ${
          isOpen ? styles.active : styles.hidden
        }`}
      />
    </button>
  );
};

export default HamburgerButton;
