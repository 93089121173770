import React, { createContext, useState, ReactNode } from "react";

// Modelo para o certificado
interface CertificateModel {
  CpfCnpj: string;
  NomeRazaoSocial: string;
}

// Propriedades do AuthContext
interface AuthContextProps {
  accessToken: string;
  setAccessToken: React.Dispatch<React.SetStateAction<string>>;
  autenticated: boolean;
  setAutenticated: React.Dispatch<React.SetStateAction<boolean>>;
  queryResponseAuthentication: string;
  setQueryResponseAuthentication: React.Dispatch<React.SetStateAction<string>>;
  certificate: CertificateModel;
  setCertificate: React.Dispatch<React.SetStateAction<CertificateModel>>;
}

// Estado inicial
const initialCertificate: CertificateModel = {
  CpfCnpj: "CPF/CNPJ",
  NomeRazaoSocial: "Nome/Razão Social",
};

const initialAuthContext: AuthContextProps = {
  accessToken: "",
  setAccessToken: () => {},
  autenticated: false,
  setAutenticated: () => {},
  queryResponseAuthentication: "",
  setQueryResponseAuthentication: () => {},
  certificate: initialCertificate,
  setCertificate: () => {},
};

// Criação do contexto
export const AuthContext = createContext<AuthContextProps>(initialAuthContext);

// Provedor do AuthContext
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<string>(
    initialAuthContext.accessToken
  );
  const [autenticated, setAutenticated] = useState<boolean>(
    initialAuthContext.autenticated
  );
  const [queryResponseAuthentication, setQueryResponseAuthentication] =
    useState<string>(initialAuthContext.queryResponseAuthentication);
  const [certificate, setCertificate] =
    useState<CertificateModel>(initialCertificate);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken,
        autenticated,
        setAutenticated,
        queryResponseAuthentication,
        setQueryResponseAuthentication,
        certificate,
        setCertificate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
