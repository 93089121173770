import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Button, Carousel } from "react-bootstrap";

// Importando components
import HamburgerButton from "../../components/HamburgerButton";

// Importando imagens diretamente
import AgrocomplyLogo from "../../assets/images/logos/agrocomply-logo.png";
import SafeIdLogo from "../../assets/images/logos/safeid-logo.png";
import styles from "../../styles/pages/Home.module.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

const Home: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false); // Estado para verificar se é mobile
  const [showMotionLogo, setShowMotionLogo] = useState(true); // Estado para controlar o MotionLogo
  const [currentPost, setCurrentPost] = useState(0); // Índice do post/slide ativo
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Referência para o ID do intervalo
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLeftSectionVisible, setIsLeftSectionVisible] = useState(false);

  const cdnBaseURL = "https://agrocomplycdn-fyhnf0edbmh0amab.z03.azurefd.net";

  // Verifica se o dispositivo é mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    checkMobile(); // Checa na primeira renderização
    window.addEventListener("resize", checkMobile); // Atualiza ao redimensionar

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Importando imagens diretamente
  // const AgrocomplyLogo = `${cdnBaseURL}/images-logos/agrocomply-logo.webp"`;
  // const SafeIdLogo = `${cdnBaseURL}/images-logos/safeid-logo.webp"`;

  // Define as imagens com base no tipo de dispositivo
  const Home3 = `${cdnBaseURL}/${
    isMobile ? "images-background-mobile" : "images-background"
  }/Home3.webp`;
  const Home5 = `${cdnBaseURL}/${
    isMobile ? "images-background-mobile" : "images-background"
  }/Home5.webp`;
  const Home1 = `${cdnBaseURL}/${
    isMobile ? "images-background-mobile" : "images-background"
  }/Home1.webp`;
  const MotionLogo = `${cdnBaseURL}${
    isMobile ? "motion-mobile/MotionLogo.webm" : "motion/MotionLogo.mp4"
  }`;

  const posts = [
    {
      content: "Padrões Seguros de<br />Rastreabilidade<br />Agropecuária",
      background: Home3,
    },
    {
      content: "Inovação e<br />Sustentabilidade<br />no Agro",
      background: Home5,
    },
    {
      content: "Compliance e<br />Segurança nas<br />Operações Rurais",
      background: Home1,
    },
  ];

  const handleLogin = () => {
    window.location.href =
      "https://pscsafeweb.safewebpss.com.br/Service/Microservice/OAuth/api/v0/oauth/authorize?response_type=code&client_id=agrocomply-authentication-hbauwnvm&redirect_uri=https%3a%2f%2fauthentication.agrocomply.com.br%2fauthentication%2f&state=zRLDN0xx0d4091PIROXgnl208gsmg8h3peu2oBlxo0EwvF80qj&lifetime=3600&scope=signature_session&code_challenge=F6hFrB5m0i0bWD9pTJC-hCGTX0xi2dy7v1AR5jjbQoM&code_challenge_method=S256";
  };

  const validateForm = () => {
    let isValid = true;

    if (!email) {
      setEmailError("Campo obrigatório");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("E-mail inválido");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Campo obrigatório");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      console.log("Formulário enviado");
      // Coloque a lógica de envio aqui
    }
  };

  // Função para iniciar ou reiniciar o intervalo
  const startInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Limpa o intervalo existente
    }
    intervalRef.current = setInterval(() => {
      setCurrentPost((prevPost) => (prevPost + 1) % posts.length);
    }, 4000); // Troca a cada 4 segundos
  }, [posts.length]);

  // Efeito para iniciar o intervalo ao carregar o componente
  useEffect(() => {
    startInterval(); // Inicia o intervalo

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Limpa o intervalo ao desmontar
      }
    };
  }, [startInterval]);

  // Reinicia o intervalo ao alterar manualmente
  useEffect(() => {
    startInterval();
  }, [currentPost, startInterval]);

  const handleSelect = (selectedIndex: number) => {
    setCurrentPost(selectedIndex); // Atualiza o slide ativo
  };

  return (
    <div className={styles.Home}>
      {/* {showMotionLogo ? (
        <div className={styles.motionContainer}>
          <video
            className={styles.motionLogo}
            src={MotionLogo}
            autoPlay
            muted
            onEnded={() => setShowMotionLogo(false)}
          />
        </div>
      ) : (
        <> */}
      {/* Botão Hamburger */}
      <HamburgerButton setIsLeftSectionVisible={setIsLeftSectionVisible} />
      {/* <button
        className={styles.hamburgerButton}
        onClick={() => setIsLeftSectionVisible((prev) => !prev)}
        aria-label="Menu"
      >
        <FontAwesomeIcon icon={faBars} />
      </button> */}

      {/* Seção Esquerda */}
      <div
        className={`${styles.leftSection} ${
          isLeftSectionVisible ? styles.leftSectionVisible : ""
        }`}
      >
        <img
          src={AgrocomplyLogo}
          alt="Agrocomply Logo"
          className={styles.logo}
        />
        <h1>Bem-vindo(a) à Agrocomply!</h1>
        <p>Para entrar, insira seus dados:</p>

        {/* Substituído o Container */}
        <div className={styles.customContainer}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail" className={styles.formGroup}>
              <Form.Label className={styles.label}>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder="Digite seu e-mail"
                className={`${styles.input} ${
                  emailError ? styles.inputError : ""
                }`}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!e.target.value) {
                    setEmailError("Campo obrigatório");
                  } else if (!/\S+@\S+\.\S+/.test(e.target.value)) {
                    setEmailError("E-mail inválido");
                  } else {
                    setEmailError("");
                  }
                }}
              />
              {emailError && (
                <p className={styles.errorText}>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={styles.errorIcon}
                  />
                  {emailError}
                </p>
              )}
            </Form.Group>

            <Form.Group controlId="formPassword" className={styles.formGroup}>
              <Form.Label className={styles.label}>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Digite sua senha"
                className={`${styles.input} ${
                  passwordError ? styles.inputError : ""
                }`}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (!e.target.value) {
                    setPasswordError("Campo obrigatório");
                  } else {
                    setPasswordError("");
                  }
                }}
              />
              {passwordError && (
                <p className={styles.errorText}>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className={styles.errorIcon}
                  />
                  {passwordError}
                </p>
              )}
            </Form.Group>

            <Button type="submit" className={styles.submitBtn}>
              Entrar
            </Button>
          </Form>

          <div className={styles.linkContainer}>
            <a href="/" className={styles.forgotPassword}>
              Esqueci minha senha
            </a>
            <p>
              Não tem uma conta?{" "}
              <a href="/" className={styles.createAccount}>
                Crie uma agora mesmo!
              </a>
            </p>
          </div>

          <div className={styles.partnerAccess}>
            <Form.Label className={styles.label}>
              Acesso clientes e parceiros:
            </Form.Label>
            <Button className={styles.partnerButton} onClick={handleLogin}>
              <img
                src={SafeIdLogo}
                alt="SafeId Logo"
                className={styles.safeIdLogo}
              />
            </Button>
          </div>
        </div>
      </div>

      {/* Seção Direita com Carousel */}
      <div className={styles.rightSection}>
        <Carousel
          activeIndex={currentPost}
          onSelect={handleSelect}
          controls={true}
          indicators={false}
        >
          {posts.map((post, index) => (
            <Carousel.Item key={index} className={styles.carouselItem}>
              {/* Imagem com fade transition */}
              <div
                className={`${styles.fadeBackground} ${
                  currentPost === index ? styles.activeBackground : ""
                }`}
                style={{
                  backgroundImage: `url(${post.background})`,
                }}
              ></div>
            </Carousel.Item>
          ))}
        </Carousel>

        {/* Legenda Fixa Fora do Carousel */}
        <div className={styles.staticCaption}>
          <h3 className={styles.postsTitle}>Últimos Posts</h3>
          <p
            className={styles.postsContent}
            dangerouslySetInnerHTML={{
              __html: posts[currentPost].content,
            }}
          />
        </div>

        <div className={styles.hexagonContainer}>
          {posts.map((_, index) => (
            <Button
              key={index}
              className={`${styles.hexagon} ${
                currentPost === index ? styles.activeHexagon : ""
              }`}
              onClick={() => setCurrentPost(index)}
            ></Button>
          ))}
        </div>
      </div>
      {/* </>
      )} */}
    </div>
  );
};

export default Home;
